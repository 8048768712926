<template>
  <div class="courseUserDetail">
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title" style="margin-bottom:0">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">数据统计</a>
                <i>></i>
                <a href="javascript:;" @click=" goback">发布课程使用</a>
                <i>></i>
                <a href="javascript:;" @click="init" class="cur-a">使用详情</a>
                </span>
            </div>
        </div>
    </div>
    <div class="searchboxfl">
       <div class="operationControl " >
        <div class="searchbox">
          <div title="课程名称" class="searchboxItem ci-full">
            <span class="itemLabel">课件名称:</span>
            <el-input v-model="selectData.kpointName" type="text" size="small" placeholder="请输入班级名称" clearable />
          </div>
          <div class="df">
            <el-button type="primary" class="bgc-bv" round @click="getData">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll ">
      <div class="ovy-a">
        <div style="margin: 20px">
              <span>实际使用课件数量: {{totalCount}} 个</span>
              <span style="margin:0 15px;">实际使用总学时: {{totalLessonNum}} 学时</span>
            </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
            <el-table-column label="课件名称" align="center" show-overflow-tooltip prop="kpointName" />
            <el-table-column label="课件学时" align="center" show-overflow-tooltip prop="lessonNum" />
          <el-table-column label="完成人数" align="center" show-overflow-tooltip prop="completeCount" />
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
   
   
    
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUserDetail",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
     
      selectData:{
          "buyId": "",
          "kpointName": "",
          "projectId": "",
      },
      totalCount:"",
      totalLessonNum:"",

    };
  },
  computed: {},
  created() {
  },
  methods: {
    goback(){
       
      this.$router.push({
        path: "/web/set/courseUser",
        
      });
    },
    getData(pageNum) {

      this.selectData.projectId=this.$route.query.projectId;
      this.selectData.buyId=this.$route.query.buyId;
      const params = {
        projectId:this.selectData.projectId,
        buyId:this.selectData.buyId,
        pageSize: this.pageSize
      };
      if(this.selectData.kpointName) {
        params.kpointName =this.selectData.kpointName

      }
      this.doFetch({
        url: "/biz/buy/stat/courseUseDetail",
        
        params,
        pageNum:this.pageNum
      });
      this.$post("/biz/buy/stat/courseUseDetailStat",{...this.selectData}).then(ret => {
        if(ret.status=="0"){
          this.totalCount=ret.data.totalCount;
           this.totalLessonNum=ret.data.totalCount;
        }
       
        
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 100;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  mounted: function() {
    this.getTableHeight();
  }
};
</script>
<style lang="less" scope>
.courseUserDetail{
  background-color: #f5f5f6;
  .searchboxfl {
    background-color: #fff;
    margin: 0 .675rem;
    padding-top: 20px;
  }
  .pageNum{
    padding: 0 1rem;
  }
}

</style>